import React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import './UXCCard.css';

function UXCCard(props) {
  // Component logic and JSX
  return (
    <Card sx={{ minWidth: 275 }} className="uxc-comp-card-outer">
      <CardContent className="uxc-comp-cardcontent">
        <div style={{ width: '100%', height: '150px', backgroundColor: 'gray'}}>...</div>
        <div className="uxc-comp-card-lower">
          <h3>{props.cardHeading}</h3>
          <div>
              {props.shortDescription}
          </div>
        </div>
      </CardContent>
      <CardActions>
        <Button size="small">{props.buttonTitle}</Button>
      </CardActions>
    </Card>
  );
}

export default UXCCard;