import * as React from 'react';
import './PageLogin.css';
import Box from '@mui/material/Box';
import UXCCard from '../Components/UXCCard/UXCCard';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import UXCMarquee from '../Components/UXCMarquee/UXCMarquee';
import UXCContentBlock from '../Components/UXCContentBlock/UXCContentBlock';

export default function PageLogin() {
  return (
    <div>
    <UXCMarquee primaryText="Are You a Part of it Yet?" subText="(subtext)"/>
        <UXCContentBlock width="uxc-comp-content-block-width-full" background="uxc-comp-content-block-bg-white" headingTwo="Login or Sign Up">
            Test
        </UXCContentBlock>
  </div>
  );
}