import './App.css';
import UXCHeader from './Components/UXCHeader/UXCHeader';
import Box from '@mui/material/Box';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PageHome from './Pages/PageHome';
import PageAbout from './Pages/PageAbout';
import PageWorkInUX from './Pages/PageWorkInUX';
import PageUXTraining from './Pages/PageUXTraining';
import PageUXCommunity from './Pages/PageUXCommunity';
import PageProducts from './Pages/PageProducts';
import PageServicesBrokerage from './Pages/PageServicesBrokerage';
import PageOurVision from './Pages/PageOurVision';
import PageLogin from './Pages/PageLogin';


function App() {
  return (
    <div className="App">
      <UXCHeader />
      <Router>
        <Routes>
          <Route exact path="/" element={<Home/>}/>
          <Route exact path="/home" element={<Home/>}/>
          <Route exact path="/about" element={<About/>}/>
          <Route exact path="/work-in-ux" element={<WorkInUX />}/>
          <Route exact path="/ux-training" element={<UXTraining />}/>
          <Route exact path="/ux-community" element={<UXCommunity />}/>
          <Route exact path="/products" element={<Products />}/>
          <Route exact path="/services-brokerage" element={<ServicesBrokerage />}/>
          <Route exact path="/our-vision" element={<OurVision />}/>
          <Route exact path="/login" element={<Login />}/>
        </Routes>
    </Router>
    </div>
  );
}

function Home() {
  return (
    <PageHome />
  );
}

function About() {
  return (
    <PageAbout />
  );
}

function WorkInUX() {
  return (
    <PageWorkInUX />
  );
}

function UXTraining() {
  return (
    <PageUXTraining />
  );
}

function UXCommunity() {
  return (
    <PageUXCommunity />
  );
}

function Products() {
  return (
    <PageProducts />
  );
}

function ServicesBrokerage() {
  return (
    <PageServicesBrokerage />
  );
}

function OurVision() {
  return (
    <PageOurVision />
  );
}

function Login() {
  return (
    <PageLogin />
  );
}

export default App;
