import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import UXCLogo from '../../UXCLogoSmall.png';
import './UXCHeader.css';

export default function UXCHeader() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" className="uxc-comp-header-outer">
        <Toolbar>
        <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <a href="/"><img src={UXCLogo} /></a>
          <div style={{ justifyContent: 'flex-end', width: '100%', textAlign: 'right' }}>
          <a href="/ux-training"><Button color="inherit" className="uxc-comp-header-button">UX Training</Button></a>
          <a href="/work-in-ux"><Button color="inherit" className="uxc-comp-header-button">Work in UX</Button></a>
          <a href="/ux-community"><Button color="inherit" className="uxc-comp-header-button">UX Community</Button></a>
          <a href="/products"><Button color="inherit" className="uxc-comp-header-button">Products</Button></a>
          <a href="/services-brokerage"><Button color="inherit" className="uxc-comp-header-button">Services Brokerage</Button></a>
          <a href="/our-vision"><Button color="inherit" className="uxc-comp-header-button">Our Vision</Button></a>
          <a href="/login"><Button color="inherit" className="uxc-comp-header-button ux-comp-header-button-login">Join or Login</Button></a>
          </div>
        </Toolbar>
      </AppBar>
    </Box>
  );
}