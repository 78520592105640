import * as React from 'react';
import './PageOurVision.css';
import Box from '@mui/material/Box';
import UXCCard from '../Components/UXCCard/UXCCard';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import UXCMarquee from '../Components/UXCMarquee/UXCMarquee';
import UXCContentBlock from '../Components/UXCContentBlock/UXCContentBlock';

export default function PageOurVision() {
  return (
    <div>
    <UXCMarquee primaryText="Building UX Connections" subText="(subtext)"/>
        <UXCContentBlock width="uxc-comp-content-block-width-full" background="uxc-comp-content-block-bg-darkblue" headingTwo="We Challenge UX Convention">
            Test
        </UXCContentBlock>
        <UXCContentBlock width="uxc-comp-content-block-width-full" background="uxc-comp-content-block-bg-white" headingTwo="We UX Build Leaders">
            Test
        </UXCContentBlock>
  </div>
  );
}