import React from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import './UXCMarquee.css';
import { Button } from '@mui/material';

function UXCMarquee(props) {
  // Component logic and JSX
  const isVisible = props.buttonTitle;

  return (
    <div className="uxc-comp-marquee-wrapper">
        <Container maxWidth="lg">
            <h1>{props.primaryText}</h1>
            <p>{props.subText}</p>
            
            {isVisible && <Button variant="contained">{props.buttonTitle}</Button>}
        </Container>
    </div>
  );
}

export default UXCMarquee;