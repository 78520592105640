import * as React from 'react';
import './PageHome.css';
import Box from '@mui/material/Box';
import UXCCard from '../Components/UXCCard/UXCCard';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import UXCMarquee from '../Components/UXCMarquee/UXCMarquee';
import UXCContentBlock from '../Components/UXCContentBlock/UXCContentBlock';

export default function PageHome() {
  return (
    <div>
    <UXCMarquee primaryText="Learn Enterprise UX and Immediately Apply It." subText="Start from your experience level, learn, and get work." buttonTitle="Explore UX Training" />
        <UXCContentBlock width="uxc-comp-content-block-width-full" background="uxc-comp-content-block-bg-darkblue" headingTwo="Train, Work, and Connect in UX.">
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={4}>
                        <UXCCard cardHeading="Train On Enterprise UX" shortDescription="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt " buttonTitle="Learn About UX Training" />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <UXCCard cardHeading="Work On Live Projects" shortDescription="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt " buttonTitle="Join a Project" />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <UXCCard cardHeading="Mentorship and Connection" shortDescription="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt " buttonTitle="Collaborate" />
                    </Grid>
                </Grid>
            </Box>
        </UXCContentBlock>
        <UXCContentBlock width="uxc-comp-content-block-width-full" background="uxc-comp-content-block-bg-white" headingTwo="A Refreshing Way to Grow at Any UX Level">
            Test
        </UXCContentBlock>
  </div>
  );
}