import * as React from 'react';
import './PageUXTraining.css';
import Box from '@mui/material/Box';
import UXCCard from '../Components/UXCCard/UXCCard';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import UXCMarquee from '../Components/UXCMarquee/UXCMarquee';
import UXCContentBlock from '../Components/UXCContentBlock/UXCContentBlock';

export default function PageUXTraining() {
  return (
    <div>
    <UXCMarquee primaryText="Train on Enterprise UX, Land the Right Role" subText="(subtext)" buttonTitle="Explore UX Training" />
        <UXCContentBlock width="uxc-comp-content-block-width-full" background="uxc-comp-content-block-bg-darkblue" headingTwo="How User Continuity is Different">
            Test
        </UXCContentBlock>
        <UXCContentBlock width="uxc-comp-content-block-width-full" background="uxc-comp-content-block-bg-white" headingTwo="Training Options">
            Test
        </UXCContentBlock>
        <UXCContentBlock width="uxc-comp-content-block-width-full" background="uxc-comp-content-block-bg-darkblue" headingTwo="Mentorship Options">
            Test
        </UXCContentBlock>
  </div>
  );
}